<template>
    <div>
        <input type="file" @change="uploadFileAsync($event)" />
        <p v-if="uploadStatus">{{uploadStatus}}</p>
        <p v-if="uploadProgress">上传进度：{{uploadProgress}}</p>
    </div>
</template>

<script>
import {randomString} from "@/utils/utils";
export default {
    name: "WsUploadComponent",
    data() {
        return {
            uploadStatus: "",
            chunkSize: 1024 * 1024,
            socket: null,
            uploadProgress: 0,
        }
    },
    created() {
        this.socket = new WebSocket("ws://127.0.0.1:8082");
        this.socket.onopen = () => {
            console.log("连接成功")
        }
        this.socket.onmessage = (event) => {
            // console.log("evt", event)
            const message = JSON.parse(event.data)
            console.log("res", message)
        }
        this.socket.onclose = () => {
            console.log("close")
        }
        this.socket.onerror = () => {
            console.log("error")
        }
    },
    methods: {
        async uploadFileAsync(event) {
            const file = event.target.files[0]
            // const chunkSize = this.chunkSize
            // const totalChunks = Math.ceil(file.size / chunkSize)
            const totalChunks = 100
            const chunkSize = file.size / 100
            let chunkIndex = 0
            while (chunkIndex < totalChunks) {
                const start = chunkIndex * chunkSize
                const end = Math.min(start + chunkSize, file.size)
                const chunk = file.slice(start, end)

                await this.uploadChunk(chunk, chunkIndex, totalChunks, file.name)
                chunkIndex++;
                this.uploadProgress = Math.floor((chunkIndex / totalChunks) * 100)
            }

        },
        uploadChunk(chunk, chunkIndex, totalChunks, fileName) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64ChunkData = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''))
                    const data = {
                        type: "upload_chunk",
                        payload: {
                            chunkData: base64ChunkData,
                            chunkIndex,
                            totalChunks,
                            fileName,
                            random: randomString(8),
                            token: randomString(6),
                        }
                    }
                    this.socket.send(JSON.stringify(data))
                    resolve()
                };
                reader.readAsArrayBuffer(chunk)
            })

        },

        uploadFile(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            // reader.onload = () => {
            //     const base64FileData = btoa(new Uint8Array(reader.result).reduce((data, btye) => data + String.fromCharCode(btye), ''))
            //     const data = {
            //         type: "upload_file",
            //         payload: {
            //             fileData: base64FileData,
            //             fileName: file.name,
            //         }
            //     };
            //     this.socket.send(JSON.stringify(data));
            // };
            // reader.readAsArrayBuffer(file);
            const totalChunks = Math.ceil(file.size / this.chunkSize)
            for (let i = 0; i < totalChunks; i++) {
                const start = i * this.chunkSize;
                const end = Math.min(start + this.chunkSize, file.size)
                const chunk = file.slice(start, end)
                const reader = new FileReader()
                reader.onload = () => {
                    const data = {
                        type: "upload_chunk",
                        payload: {
                            chunk: (new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), "")),
                            chunkNumber: i,
                            totalChunks: totalChunks,
                            fileName: file.name
                        }
                    }
                    this.socket.send(JSON.stringify(data))
                }
                console.log("i的值", i)
                reader.readAsArrayBuffer(chunk)
            }
        }
    }
}
</script>

<style scoped>

</style>